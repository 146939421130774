import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

function Seo({ title, description, keywords, image, canonical, ogType, ogUpdated, ogLocale }) {
  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:site_name" content="SeaMC" />
      {/* <meta property="og:app_id" content="111" /> */}
      {canonical && <meta property="og:url" content={`${process.env.NEXT_PUBLIC_FRONT_END}/${canonical}`} />}
      <meta property="og:type" content={ogType || 'website'} />
      {canonical && <link rel="canonical" href={`${process.env.NEXT_PUBLIC_FRONT_END}/${canonical}`} />}
      {ogLocale && <meta property="og:locale" content={ogLocale} />}
      {ogUpdated && <meta property="og:locale" content={ogUpdated} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
    </Head>
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  canonical: PropTypes.string,
  ogType: PropTypes.string,
  ogUpdated: PropTypes.string,
  ogLocale: PropTypes.string,
};

export default Seo;
