
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import Seo from '../components/util/seo';
import { capitalize } from '../util/stringUtils';
import { client } from '~/components/App';
import { getResourcesQ } from '~/components/resource/queries';
import Promo from '~/components/home/promo';

const Carousel = dynamic(() => import('~/components/carousel/carousel'), {
  ssr: true,
});

function Home({ categories }: { categories: { id: string; data: any }[] } | null) {
  const router = useRouter();
  const { t } = useTranslation('home');
  const { t: th } = useTranslation('header');

  return (
    <>
      <Head>
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Head>
      <Seo title={`SeaMC ${capitalize(th('marketplace'))}`} description={t('shortDescription')} keywords="laserflaremarket market lfm madfox laserflare nextjs next reactjs react graphql apollo tailwind nodejs mongo" ogType="website" canonical={router.asPath} />
      <svg className="" id="visual" viewBox="0 0 960 54" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
        <path
          d="M0 20L22.8 20.8C45.7 21.7 91.3 23.3 137 23.3C182.7 23.3 228.3 21.7 274 20.8C319.7 20 365.3 20 411.2 21.3C457 22.7 503 25.3 548.8 27.5C594.7 29.7 640.3 31.3 686 31.3C731.7 31.3 777.3 29.7 823 28.5C868.7 27.3 914.3 26.7 937.2 26.3L960 26L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          className="fill-current text-th-navbar"
          strokeLinecap="round"
          strokeLinejoin="miter"
        />
      </svg>
      <h1 className="text-xl font-bold text-center">Home sweet home.</h1>
      <ul className="max-w-7xl mx-auto flex flex-col md:flex-row gap-4 p-4 text-white">
        <Promo className="bg-purple-500 p-4 w-full md:w-1/2 h-32 flex flex-col">
          <h2 className="text-xl font-bold">Join our community</h2>
          <ul className="flex gap-4 mt-auto">
            <li>
              <Link href="">
                <a className="flex items-center gap-2 text-lg font-semibold">
                  <Image src="/images/svg/discord.svg" width={32} height={32} alt="https://discord.gg/mtrwh2U6eg" />
                  Discord
                </a>
              </Link>
            </li>
          </ul>
        </Promo>
        <Promo className="bg-emerald-500 p-4 w-full md:w-1/2 h-32 flex" href="/categories">
          <h2 className="text-xl font-bold border-b hover:border-purple-500 transition-all duration-500 mr-auto w-auto">Check all categories &gt;</h2>
        </Promo>
        <Promo className="bg-blue-500 p-4 w-full md:w-1/3 flex" href="/resources">
          <h2 className="text-xl font-bold border-b hover:border-purple-500 transition-all duration-500 mr-auto w-auto">Explore all resources &gt;</h2>
        </Promo>
      </ul>
      <div className="px-4 max-w-7xl mx-auto">
        {categories?.map((category) => (
          <Carousel key={category.id} id={category.id} items={category?.data?.resources} />
        ))}
      </div>
    </>
  );
}

export default Home;

 async function _getStaticProps(props) {
  const queries = [
    { id: 'mostViewed', sortBy: 'views', orderBy: -1 },
    { id: 'mostDownloaded', sortBy: 'downloads', orderBy: -1 },
    { id: 'updatedRecently', sortBy: 'updatedAt', orderBy: -1 },
  ];

  const categories = await Promise.all(
    queries.map(async (query) => {
      const { data } = await client
        .query({
          query: getResourcesQ,
          variables: {
            locale: props?.locale || 'en',
            limit: 10,
            sortBy: query.sortBy,
            order: query.orderBy,
          },
        })
        .catch((e) => {
          console.error(`Can't prerender /${props?.locale}/resources`, e);
          return { data: null, error: e };
        });
      return {
        id: query.id,
        data: data.getResources,
      };
    })
  );

  console.log(categories);

  return {
    props: { categories: categories || null },
    revalidate: 30, // In seconds
  };
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  